const getCoordinatesForAddress = async (address) => {
  const { google } = window;

  if (!google) return;

  const geocoder = new google.maps.Geocoder();

  if (!geocoder) return;

  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, (results, status) => {
      const { GeocoderStatus } = google.maps;
      const {
        OK,
        ZERO_RESULTS,
      } = GeocoderStatus;

      if (status === OK && status !== ZERO_RESULTS) {
        const { location } = results[0].geometry;
        const latlng = new google.maps.LatLng(location.lat(), location.lng());

        if (latlng) {
          resolve(latlng);
        } else {
          reject(new Error(`Geocoder error ${status}`));
        }
      }
    });
  });
};

export {
  getCoordinatesForAddress,
};
